// src/App.js
import { NextUIProvider } from '@nextui-org/react';
import axios from "axios";
import React from 'react';
import { Route, Routes, useHref, useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './globals.css';
import About from './pages/About';
import DynamicForm from './pages/admin/AddForms';
import AdminForms from './pages/admin/Forms';
import AdminHome from './pages/admin/Home';
import FormResult from './pages/FormResult';
import FormSelect from './pages/FormSelect';
import FormSteps from './pages/FormSteps';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import MyForms from './pages/MyForms';
import { useAuth } from "./provider/authProvider";
import { ProtectedRoute } from './routes/ProtectedRoute';

function App() {
  const navigate = useNavigate();
  const {token} = useAuth();

  axios.interceptors.request.use(
    config => {
      if (!config.headers.Authorization) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
  
      return config;
    },
    error => Promise.reject(error)
  );

  return (
    <NextUIProvider navigate={navigate} useHref={useHref}>
      <main className="dark text-foreground bg-background">
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path='/logout' element={<Logout />} />
            <Route path='/forms' element={<FormSelect />} />
            <Route path='/form/:formId' element={<FormSteps />} />
            <Route path='/form/result/:submitedId' element={<FormResult />} />
            <Route path='/myforms' element={<MyForms />} />
            <Route path='admin' element={<AdminHome />}>
              <Route path='forms' element={<AdminForms />} />
              <Route path='forms/new' element={<DynamicForm />}/>
              <Route path='forms/:formId' element={<DynamicForm />}/>
            </Route>
          </Route>
        </Routes>
      <Footer />
      </main>
    </NextUIProvider>
  );
}

export default App;