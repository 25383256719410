import { useAuth } from "../provider/authProvider";

const Logout = () => {
  const { logout } = useAuth();
  
  const handleLogout = () => {
    logout();
  };

  setTimeout(() => {
    handleLogout();
  }, 3 * 1000);

  return <>Logout Page</>;
};

export default Logout;