// src/pages/Contact.jsx
import { motion } from "framer-motion";
import React from 'react';

const About = () => {
  return (
    <div className="relative justify-center items-center">
      <section className="max-w-screen-xl mx-auto px-4 py-28 gap-12 md:px-8 flex flex-col justify-center items-center">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{ duration: 0.6, type: "spring", bounce: 0 }}
          className="flex flex-col justify-center items-center space-y-5 max-w-4xl mx-auto text-center"
        >
          <h1 className="text-4xl font-medium tracking-tighter mx-auto md:text-6xl text-pretty ">
            O firmie
          </h1>
          <p className="max-w-2xl text-lg mx-auto text-muted-foreground text-balance">
                    Posejdon.ai to nowoczesna firma
          wykorzystująca sztuczną inteligencję do
          tworzenia wniosków o fundusze unijne i
          globalne. Dzięki naszej zaawansowanej
          technologii pomagamy firmom i
          organizacjom skutecznie aplikować o
          granty, zwiększając szanse na pozyskanie
          środków na rozwój innowacyjnych
          projektów.
          </p>
        </motion.div>
      </section>
    </div>
  );
};

export default About;
