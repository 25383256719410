import { Accordion, AccordionItem, Button, Card, CardBody, CircularProgress } from '@nextui-org/react';
import { pdf } from '@react-pdf/renderer';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { saveAs } from 'file-saver';
import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../provider/authProvider';
import MyDocument from './FormPdf';

const FormResult = () => {
    const { submitedId } = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [aiResponses, setAiResponses] = useState([]); // Stan do przechowywania odpowiedzi AI
    const [errorMessage, setErrorMessage] = useState(null);
    const { token } = useAuth();

    const generatePdfDocument = async (fileName, pdfDocumentComponent) => {
        const blob = await pdf(pdfDocumentComponent).toBlob();
        saveAs(blob, fileName);
    };

    useEffect(() => {
        const eventSource = new EventSourcePolyfill(`http://localhost:3001/api/forms/result/${submitedId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            heartbeatTimeout: 60000
        });

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.complete) {
                setIsLoading(false);
                eventSource.close();
            } else {
                setAiResponses((prevResponses) => [...prevResponses, data]); // Dodajemy odpowiedź AI do listy
            }
        };

        eventSource.onerror = (error) => {
            console.log(error);
            setIsLoading(false); // Ładowanie zakończone
            setErrorMessage('Wystąpił błąd połączenia SSE', error);
            eventSource.close();
        };
    }, []);

    const renderAiResponse = (aiResponse, i) => {
        const responseObject = JSON.parse(aiResponse.response); // Zakładam, że aiResponse jest w formacie JSON
        return (
            <AccordionItem key={i} aria-label={aiResponse.step} title={aiResponse.step}>
            {Object.entries(responseObject).map(([title, text], index) => (
              <div key={index}>
                <p className='text-fuchsia-600 text-xl tracking-wide'>
                  {title}
                </p>
                <p className='text-sm'>
                  {text}
                </p>
              </div>
            ))}
          </AccordionItem>
        );
      };

    return (
        <motion.section
            initial={{ y: 20, opacity: 0 }}
            whileInView={{
                y: 0,
                opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5, type: "spring", bounce: 0 }}
            className="max-w-screen-xl w-full mx-auto px-4 py-28 gap-5 md:px-8 flex flex-col justify-center items-center"
        >
            <div className="mt-16 gap-10 flex flex-col place-content-center w-3/4">
                <Card
                    shadow="none"
                    className={`relative rounded-[20px] p-[5px] will-change-transform sm:scale-110`}>
                    <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#016FEE_70%,#C7DBFB_100%)]" />
                    <div className="z-[2] flex flex-col justify-between w-full h-full bg-card rounded-[18px] p-5">
                        <CardBody className="w-full flex flex-col gap-3 justify-center">
                            <Accordion>
                            {aiResponses.map((response, index) => (
                                renderAiResponse(response,index)
                            ))}
                            </Accordion>
                            {isLoading && (
                                <CircularProgress label="Loading..." />
                            )
                            }
                            <div>
                            {!isLoading && (
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        generatePdfDocument('abc.pdf', <MyDocument obj={aiResponses} />)
                                    }}
                                >
                                    Pobierz pdf
                                </Button>
                            )}
                            </div>
                        </CardBody>
                    </div>
                </Card>
            </div>
        </motion.section>
    );
}

export default FormResult;