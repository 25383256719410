// src/pages/Login.jsx
import { Button, Card, CardBody, CardFooter, Input } from '@nextui-org/react';
import axios from 'axios';
import { motion } from "framer-motion";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../provider/authProvider";

const Login = ({ setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    axios.post('http://127.0.0.1:3001/api/user/login', {email: username, password: password})
    .then(response => {
        login(response.data.token);
        navigate('/');
    }).catch(error => {
        setError(error.response.data.error);
    });
  };

  return (
    <motion.section
    initial={{ y: 20, opacity: 0 }}
    whileInView={{
      y: 0,
      opacity: 1,
    }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay: 0.5, type: "spring", bounce: 0 }}
    className="max-w-screen-xl w-full mx-auto px-4 py-28 gap-5 md:px-8 flex flex-col justify-center items-center"
  >
    <div className="flex flex-col gap-3">
        <h3 className="text-xl font-semibold sm:text-2xl bg-gradient-to-b from-foreground to-muted-foreground text-transparent bg-clip-text">
        Zaloguj
        </h3>
        <p className="max-w-xl text-muted-foreground text-center">
        Aby miec mozliwosc generowania wnioskow
        </p>
    </div>
    <div className="mt-16 gap-10 flex flex-col place-content-center w-1/3">
        <form onSubmit={handleLogin}>
            <Card
            shadow="none"
                className={`relative rounded-[20px] p-[5px] will-change-transform sm:scale-110`}>
                    <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#016FEE_70%,#C7DBFB_100%)]"/>
                    <div className="z-[2] flex flex-col justify-between w-full h-full bg-card rounded-[18px] p-5">
                    <CardBody className="w-full flex items-start gap-3">
                            <Input
                            clearable
                            bordered
                            fullWidth
                            labelPlaceholder="Email użytkownika"
                            label="Email uzytkownika"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            classNames='flex flex-col gap-5 pb-5 w-full'
                            />
                            <Input
                            clearable
                            bordered
                            fullWidth
                            labelPlaceholder="Hasło"
                            label="Haslo"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            classNames='flex flex-col'
                            type="password"
                            />
                    </CardBody>
                    <CardFooter className="p-0 grid">
                        <Button
                        className="w-full"
                        variant="solid"
                        color="primary"
                        type='submit'
                        >
                        Zaloguj
                        </Button>
                        {error && (
                        <div className="p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                            <span className="font-medium">Błąd!</span> { error }
                        </div>
                        )}
                    </CardFooter>
                    </div>
            </Card>
        </form>
    </div>
    </motion.section>
  );
};

export default Login;
