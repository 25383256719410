import { Button, Input, Textarea } from '@nextui-org/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TiDelete } from "react-icons/ti";
import { useParams } from 'react-router-dom';

const DynamicForm = () => {
    const [name, setName] = useState('');
    const [steps, setSteps] = useState([]);
    const { formId } = useParams();

    useEffect(() => {
        if (formId) {
            const response = axios.get('http://127.0.0.1:3001/api/forms/'+formId).then(resp => {
                setName(resp.data.name);
                setSteps(resp.data.steps);
            });
        }
    }, []);

    const addStep = () => {
        setSteps([
            ...steps,
            {
                title: '',
                fields: [{ "label": "", "name": "userInput", "type": "textarea", "required": true }],
                generateFields: [],
            },
        ]);
    };

    const removeStep = (index) => {
        setSteps(steps.filter((_, i) => i !== index));
    };

    const handleStepChange = (index, field, value) => {
        const newSteps = [...steps];
        newSteps[index][field] = value;
        setSteps(newSteps);
    };

    const addGenerateField = (stepIndex) => {
        const newSteps = [...steps];
        newSteps[stepIndex].generateFields.push({
            question: '',
            description: '',
            params: {
                min_characters: 0,
                max_characters: 0,
            },
        });
        setSteps(newSteps);
    };

    const removeGenerateField = (stepIndex, fieldIndex) => {
        const newSteps = [...steps];
        newSteps[stepIndex].generateFields = newSteps[stepIndex].generateFields.filter(
            (_, i) => i !== fieldIndex
        );
        setSteps(newSteps);
    };

    const handleGenerateFieldChange = (stepIndex, fieldIndex, field, value) => {
        const newSteps = [...steps];
        if (field === 'params') {
            newSteps[stepIndex].generateFields[fieldIndex][field] = value;
        } else {
            newSteps[stepIndex].generateFields[fieldIndex][field] = value;
        }
        setSteps(newSteps);
    };

    const handleSubmit = () => {
        const jsonData = {
            name: name,
            steps: steps.map((step) => {
                let result = {
                    title: step.title,
                    fields: step.fields,
                    generateFields: step.generateFields.map((gf) => {
                        let result = {
                            question: gf.question,
                            description: gf.description,
                            params: {
                                min_characters: gf.params.min_characters,
                                max_characters: gf.params.max_characters,
                            }
                        }
                        if (gf._id) {
                            result._id = gf._id
                        }
                        return result;
                    })
                };
                if (step._id) {
                    result._id = step._id;
                }
                return result;
            }),
        };
        console.log(JSON.stringify(jsonData, null, 2));
        if (!formId) {
            axios.post('http://127.0.0.1:3001/api/forms/', jsonData).then(resp => {
                console.log(resp);
            })
        } else {
            axios.post(`http://127.0.0.1:3001/api/forms/${formId}`, jsonData).then(resp => {
                console.log(resp);
            })
        }
    };

    return (
        <div className="p-4">
            <Input
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mb-4"
            />
            <p className='mb-4'>Kroki formularza:</p>
            {steps.map((step, index) => (
                <div key={index} className="mb-4 border-l-4 rounded-lg flex flex-row place-items-center">
                    <div>
                        <Button
                            onClick={() => removeStep(index)}
                            color="danger"
                            variant="flat"
                            className='text-2xl m-3'
                        >
                            <TiDelete />
                        </Button>
                    </div>
                    <div className='grow'>
                        <Input
                            label="Title"
                            value={step.title}
                            onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                            className="mb-2"
                        />
                        {/* GenerateFields */}
                        {step.generateFields.map((gf, gfIndex) => (
                            <div key={gfIndex} className="mb-4 border-l-4 rounded-lg flex flex-row place-items-center">
                                <div>
                                    <Button
                                        onClick={() => removeGenerateField(index, gfIndex)}
                                        color="danger"
                                        variant="flat"
                                        className='text-2xl m-3'
                                    >
                                        <TiDelete />
                                    </Button>
                                </div>
                                <div className='grow'>
                                    <Input
                                        label="Question"
                                        value={gf.question}
                                        onChange={(e) =>
                                            handleGenerateFieldChange(index, gfIndex, 'question', e.target.value)
                                        }
                                        className="mb-1"
                                    />
                                    <Textarea
                                        label="Description"
                                        value={gf.description}
                                        onChange={(e) =>
                                            handleGenerateFieldChange(
                                                index,
                                                gfIndex,
                                                'description',
                                                e.target.value
                                            )
                                        }
                                        className="mb-1"
                                    />
                                    <div className="flex space-x-2">
                                        <Input
                                            label="Min Characters"
                                            type="number"
                                            value={gf.params.min_characters}
                                            onChange={(e) =>
                                                handleGenerateFieldChange(index, gfIndex, 'params', {
                                                    ...gf.params,
                                                    min_characters: parseInt(e.target.value),
                                                })
                                            }
                                        />
                                        <Input
                                            label="Max Characters"
                                            type="number"
                                            value={gf.params.max_characters}
                                            onChange={(e) =>
                                                handleGenerateFieldChange(index, gfIndex, 'params', {
                                                    ...gf.params,
                                                    max_characters: parseInt(e.target.value),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="flex space-x-2 mt-2">
                            <Button
                                onClick={() => addGenerateField(index)}
                                size="sm"
                            >
                                Dodaj GenerateField
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
            <Button onClick={addStep} className="mr-2">
                Dodaj Step
            </Button>
            <Button onClick={handleSubmit} color="success">
                Submit
            </Button>
        </div>
    );
}

export default DynamicForm;