import { Button } from "@nextui-org/button";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
} from "@nextui-org/dropdown";
import { Link } from "@nextui-org/link";
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle,
} from "@nextui-org/navbar";
import { User } from "@nextui-org/user";
import { useAuth } from "../provider/authProvider";

export default function NavBar() {
    const menuItems = [
        {
            title: "Home",
            href: "/"
        },
        {
            title: "Generuj wniosek",
            href: "/forms"
        },
        {
            title: "O nas",
            href: "/about"
        }];
    const { user } = useAuth();

    return (
        <Navbar isBlurred maxWidth="xl">
            <NavbarContent className="sm:hidden" justify="start">
                <NavbarMenuToggle />
            </NavbarContent>
            <NavbarContent className="sm:hidden pr-3" justify="center">
                <NavbarBrand>
                    <a
                        href="/"
                        className="font-light tracking-tighter text-inherit text-lg"
                    >
                        posejdon.ai
                    </a>
                </NavbarBrand>
            </NavbarContent>
            <NavbarContent className="hidden sm:flex gap-8" justify="center">
                <NavbarBrand>
                    <a
                        href="/"
                        className="font-light tracking-tighter text-2xl flex gap-3 justify-center items-center"
                    >
                        posejdon.ai
                    </a>
                </NavbarBrand>
                <NavbarItem>
                    <Button as={Link} variant="light" size="sm" href="/">
                        Home
                    </Button>
                </NavbarItem>
                <NavbarItem>
                    <Button as={Link} variant="light" size="sm" href="/forms">
                        Generuj wniosek
                    </Button>
                </NavbarItem>
                <NavbarItem>
                    <Button as={Link} variant="light" size="sm" href="/about">
                        O nas
                    </Button>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem className="hidden sm:flex">
                    {!user
                        ? <Button
                            as={Link}
                            color="primary"
                            href="/login"
                            variant="solid"
                            className="hidden sm:flex"
                            size="sm"
                        >
                            Zaloguj
                        </Button>
                        : <Dropdown placement="bottom-end" classNames={{
                            base: "before:bg-default-200", // change arrow background
                            content: "p-0 border-small border-divider bg-background",
                        }}>
                            <DropdownTrigger>
                                <User
                                    name={user.email}
                                    description={user.name}
                                    avatarProps={{
                                        src: `https://ui-avatars.com/api/?name=${user.name}`
                                    }}
                                />
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Profile Actions" variant="flat">
                                <DropdownItem key="settings">
                                    <Link href="/myforms">Moje wnioski</Link>
                                </DropdownItem>
                                {user.role == 'admin' && (
                                    <DropdownItem key="admin">
                                        <Link href="/admin">Admin</Link>
                                    </DropdownItem>
                                )}
                                <DropdownItem key="logout" color="danger">
                                    <Link href="/logout">Wyloguj</Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    }
                </NavbarItem>
            </NavbarContent>
            <NavbarMenu>
                {menuItems.map((item, index) => (
                    <NavbarMenuItem key={`${item.title}-${index}`}>
                        <Link className="w-full" href={item.href} size="lg" color="foreground">
                            {item.title}
                        </Link>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>
        </Navbar>
    );
}