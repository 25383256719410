import { Button, Card, CardBody, CardFooter, Select, SelectItem } from '@nextui-org/react';
import axios from 'axios';
import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FormSelect = () => {
    const [availableForms, setAvailableForms] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const response = axios.get('http://127.0.0.1:3001/api/forms/').then(resp => {
            console.log(resp.data);
            setAvailableForms(resp.data)
        })
    }, []);

    const handleSelect = (e) => {
        e.preventDefault();
        navigate("/form/"+selectedForm);
    };
    return (
        <motion.section
            initial={{ y: 20, opacity: 0 }}
            whileInView={{
                y: 0,
                opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5, type: "spring", bounce: 0 }}
            className="max-w-screen-xl w-full mx-auto px-4 py-28 gap-5 md:px-8 flex flex-col justify-center items-center"
        >
            <div className="flex flex-col gap-3">
                <h3 className="text-xl font-semibold sm:text-2xl bg-gradient-to-b from-foreground to-muted-foreground text-transparent bg-clip-text">
                    Wybierz wniosek
                </h3>
                <p className="max-w-xl text-muted-foreground text-center">
                    W nastepnych krokach przejdziemy do jego wypelnienia
                </p>
            </div>
            <div className="mt-16 gap-10 flex flex-col place-content-center w-1/2">
                <form onSubmit={handleSelect}>
                    <Card
                        shadow="none"
                        className={`relative rounded-[20px] p-[5px] will-change-transform sm:scale-110`}>
                        <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#016FEE_70%,#C7DBFB_100%)]" />
                        <div className="z-[2] flex flex-col justify-between w-full h-full bg-card rounded-[18px] p-5">
                            <CardBody className="w-full flex items-start gap-3">
                            {availableForms && (
                            <Select
                                items={availableForms}
                                label="Dostepne formularze"
                                placeholder="Wybierz jeden"
                                onChange={(e) => setSelectedForm(e.target.value)}
                                classNames={{
                                    popoverContent: "dark text-foreground bg-background"
                                }}
                                >
                                {(form) => <SelectItem key={form._id} className="dark text-foreground bg-background">{form.name}</SelectItem>}
                            </Select>
                            )}
                            </CardBody>
                            <CardFooter className="p-0 grid">
                                <Button
                                    className="w-full"
                                    variant="solid"
                                    color="primary"
                                    type='submit'
                                >
                                    Wybieram
                                </Button>
                            </CardFooter>
                        </div>
                    </Card>
                </form>
            </div>
        </motion.section>
    );
};

export default FormSelect;
