import { Card, CardBody, CircularProgress } from '@nextui-org/react';
import axios from 'axios';
import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import { TiChevronRightOutline } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';

const MyForms = () => {
    const [forms, setForms] = useState([]);
    const [availableForms, setAvailableForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://127.0.0.1:3001/api/forms').then((resp) => {
            setAvailableForms(resp.data);
            axios.get('http://127.0.0.1:3001/api/forms/my/all').then((resp) => {
                setForms(resp.data);
                setIsLoading(false);
            })
        });

    }, []);

    const renderElement = (obj, i) => {
        {
            const formName = availableForms.find(o => o._id === obj.formId);
            if (formName) {
                return (
                <Card className="w-full bg-violet-900" isPressable isHoverable onPress={() => navigate(`/form/result/${obj._id}`)} key={i}>
                    <CardBody className="flex-row flex justify-between">
                        <p>{obj._id} - {formName.name}</p>
                        <span><TiChevronRightOutline /></span>
                    </CardBody>
                </Card>
                );
            }
        }
    }

    return (
        <motion.section
            initial={{ y: 20, opacity: 0 }}
            whileInView={{
                y: 0,
                opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5, type: "spring", bounce: 0 }}
            className="max-w-screen-xl w-full mx-auto px-4 py-28 gap-5 md:px-8 flex flex-col justify-center items-center"
        >
            <div className="mt-16 gap-10 flex flex-col place-content-center w-3/4">
                <Card
                    shadow="none"
                    className={`relative rounded-[20px] p-[5px] will-change-transform sm:scale-110`}>
                    <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#016FEE_70%,#C7DBFB_100%)]" />
                    <div className="z-[2] flex flex-col justify-between w-full h-full bg-card rounded-[18px] p-5">
                        <CardBody className="w-full flex flex-col items-start gap-3 justify-center">
                            {isLoading && (
                                <CircularProgress label="Loading..." />
                            )
                            }
                            {forms.map((obj, i) => (
                                renderElement(obj, i)
                            ))}
                        </CardBody>
                    </div>
                </Card>
            </div>
        </motion.section>
    );
}

export default MyForms;