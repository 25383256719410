import { Button, Card, CardBody } from '@nextui-org/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TiDelete, TiEdit } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';

const AdminForms = () => {
    const [availableForms, setAvailableForms] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const response = axios.get('http://127.0.0.1:3001/api/forms/').then(resp => {
            setAvailableForms(resp.data)
        })
    }, []);

    const renderElement = (obj, i) => {
        {
                return (
                <Card className="w-full bg-blue-950 mt-4" key={i}>
                    <CardBody className="flex-row flex justify-between">
                        <p>{obj.name}</p>
                        <div className='flex flex-row gap-4'>
                            <Button variant="flat" color="warning" className='text-2xl' onClick={() => navigate(`${obj._id}`)}><TiEdit /></Button>
                            <Button variant="flat" color="danger" className='text-2xl'><TiDelete /></Button>
                        </div>
                    </CardBody>
                </Card>
                );
        }
    }

    return (
        <div className='flex flex-col'>
            <Button
                        className="w-full"
                        variant="solid"
                        color="primary"
                        onClick={() => navigate('new')}
                    >
                        Dodaj Nowy Formularz
                    </Button>
            <div className="mt-10 w-full">
                    <div className="flex flex-col justify-between w-full h-full">
                            {availableForms && availableForms.map((obj, i) => (
                                renderElement(obj, i)
                            ))}
                    </div>
            </div>
        </div>
    );
}

export default AdminForms;