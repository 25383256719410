import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

Font.register({
    family: "Roboto",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
  });
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontSize: "9pt",
    fontFamily: "Roboto",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: "20pt",
    fontWeight: "bold",
    borderColor: 'black',
    borderBottom: 1,
    borderStyle: 'solid'
  }
});

// Create Document Component
const MyDocument = ({obj}) => {
    const renderElement = (str) => {
        const responseObject = JSON.parse(str.response);
        return (
            <>
            {Object.entries(responseObject).map(([title, text], index) => (
                <View style={styles.section}>
                    <Text style={styles.title}>{title}</Text>
                    <Text>{text}</Text>
                </View>
            ))}
            </>
        )
    }
    return (
    <Document>
        <Page size="A4" style={styles.page}>
            {obj.map((resp, index) => (
                renderElement(resp,index)
            ))}
        </Page>
    </Document>
        );
};

export default MyDocument;