import { Button, Card, CardBody, CardFooter, CircularProgress, Textarea } from '@nextui-org/react';
import axios from 'axios';
import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Stepper from '../components/Stepper';

const FormSteps = () => {
    const [formData, setFormData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentUserData, setCurrentUserData] = useState('');
    const navigate = useNavigate();
    const { formId } = useParams()

    useEffect(() => {
        const response = axios.get('http://127.0.0.1:3001/api/forms/'+formId).then(resp => {
            setFormData(resp.data);
            setIsLoading(false);
            const savedFormData = JSON.parse(localStorage.getItem(`formData_${resp.data._id}`));
            if (savedFormData) {
                const key = resp.data.steps[currentStep].key;
                setCurrentUserData(savedFormData[key]);
                setUserData(savedFormData);
            } else {
                setUserData({});
            }
        })
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`http://127.0.0.1:3001/api/forms/${formData._id}/submit`,
            userData
        ).then(resp => {
            navigate(`/form/result/${resp.data._id}`);
        })
    };

    const handleChangeTextarea = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => {
          const updatedFormData = {
            ...prev,
            [name]: value,
          };
          setCurrentUserData(value)
          // Zapisz zaktualizowane dane do localStorage
          localStorage.setItem(`formData_${formData._id}`, JSON.stringify(updatedFormData));
          return updatedFormData;
        });
    };

    const handleNextStep = () => {
        if (currentStep < formData.steps.length - 1) {
          setCurrentStep((prev) => prev + 1);
          const key = formData.steps[currentStep+1]._id;
          const localUserData = JSON.parse(localStorage.getItem(`formData_${formData._id}`));
          setCurrentUserData((localUserData && key in localUserData) ? localUserData[key] : '');
        }
      };
    
      const handlePrevStep = () => {
        if (currentStep > 0) {
          setCurrentStep((prev) => prev - 1);
          const key = formData.steps[currentStep-1]._id;
          const localUserData = JSON.parse(localStorage.getItem(`formData_${formData._id}`));
          setCurrentUserData((localUserData && key in localUserData) ? localUserData[key] : '');
        }
      };

    return (
        <motion.section
            initial={{ y: 20, opacity: 0 }}
            whileInView={{
                y: 0,
                opacity: 1,
            }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5, type: "spring", bounce: 0 }}
            className="max-w-screen-xl w-full mx-auto px-4 py-28 gap-5 md:px-8 flex flex-col justify-center items-center"
        >
            {isLoading ? (
                <CircularProgress label="Loading..." />
            ) : (
                <>
                <div className="flex flex-col gap-3">
                    <h3 className="text-xl font-semibold sm:text-2xl bg-gradient-to-b from-foreground to-muted-foreground text-transparent bg-clip-text">
                        { formData.name }
                    </h3>
                    <p className="max-w-xl text-muted-foreground text-center">
                        W nastepnych krokach przejdziemy do jego wypelnienia
                    </p>
                </div>
                <div className="mt-16 gap-10 flex flex-col place-content-center w-3/4">
                    <form onSubmit={handleSubmit}>
                        <Card
                            shadow="none"
                            className={`relative rounded-[20px] p-[5px] will-change-transform sm:scale-110`}>
                            <span className="absolute inset-[-1000%] animate-[spin_6s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#016FEE_70%,#C7DBFB_100%)]" />
                            <div className="z-[2] flex flex-col justify-between w-full h-full bg-card rounded-[18px] p-5">
                                <CardBody className="w-full flex flex-row items-start gap-3">
                                {formData && (
                                    <>
                                        <div className='basis-[25%]'><Stepper steps={formData.steps} currentStep={currentStep}/></div>
                                        <div className='basis-[75%]'>
                                            <Textarea 
                                                  label="Napisz którki tekst swoimi słowami"
                                                  placeholder="My zajmiemy się resztą"
                                                  minRows={10}
                                                  maxRows={10}
                                                  value={currentUserData}
                                                  name={formData.steps[currentStep]._id}
                                                  onChange={handleChangeTextarea}
                                            />
                                            <p color="white">
                                                <strong>Opis wygeneruje następujące pola z formularza:</strong>
                                            </p>
                                            <ul className="max-w-md space-y-1 text-gray-500 list-none list-inside dark:text-gray-400">
                                                {formData.steps[currentStep].generateFields.map((obj, i) => {
                                                    return (<li key={i}><strong>{obj.question}</strong><p className='text-sm'>{obj.description}</p></li>);
                                                })
                                                }
                                            </ul>
                                        </div>
                                    </>
                                )}
                                </CardBody>
                                <CardFooter className="p-0 flex flex-row justify-center">
                                    {currentStep > 0 && (
                                    <Button
                                        className="mr-4"
                                        variant="solid"
                                        color="primary"
                                        onClick={handlePrevStep}
                                    >
                                        Poprzedni
                                    </Button>
                                    )}
                                    {currentStep < formData.steps.length-1 && (
                                    <Button
                                        className="ml-4"
                                        variant="solid"
                                        color="primary"
                                        onClick={handleNextStep}
                                    >
                                        Nastepny
                                    </Button>
                                    )}
                                    {currentStep >= formData.steps.length-1 && (
                                    <Button
                                        className="ml-4"
                                        variant="solid"
                                        color="secondary"
                                        type='submit'
                                    >
                                        Wyslij
                                    </Button>
                                    )}
                                </CardFooter>
                            </div>
                        </Card>
                    </form>
                </div>
                </>
            )}
        </motion.section>
    );
};

export default FormSteps;
