import { Card, CardBody } from '@nextui-org/react';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const AdminHome = () => {
  return (
    <div className="relative justify-center items-center">
      <section className="max-w-screen-xl mx-auto px-4 py-28 gap-12 md:px-8 flex flex-row justify-between">
        <Card className="p-4 shadow-md w-1/4">
          {/* Sidebar menu */}
          <CardBody>
            <h2 className="text-xl font-bold mb-4">Admin Menu</h2>
            <ul>
              <li className="mb-2">
                <Link to="forms" className="text-blue-500 hover:underline">Forms</Link> 
              </li>
              <li className="mb-2">
                <Link to="users" className="text-blue-500 hover:underline">Users</Link>
              </li>
            </ul>
          </CardBody>
        </Card>

        <Card className="p-4 w-3/4">
          {/* Content area */}
          <CardBody className="shadow-md rounded p-4">
            <Outlet />
          </CardBody>
        </Card>
    </section>
    </div>
  );
};

export default AdminHome;